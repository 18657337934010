import React, {Suspense, useEffect, useRef} from 'react';
import RequireAuth from "./layout/RequireAuth/RequireAuth";
import {Route, Routes, useNavigate} from "react-router-dom";
import {MainRoutes} from "./constants/routes";
import {MainPage, ImagePage, AuthPage, DressingPage, ErrorPage} from "./modules"
import {useOpenAppMutation, useSessionContinueMutation} from "./store/services/apis";
import {ErrorBoundary} from "react-error-boundary";
import AuthService from "./hooks/useLoginStatus";

function App() {
  const naviagte = useNavigate();
  const ref = useRef<any>(null);
  const [openApp] = useOpenAppMutation();
  const [sessionContinue] =
    useSessionContinueMutation();
  useEffect(() => {
    if(!sessionStorage.getItem("session_id")) {
      openApp({
        session_id: sessionStorage.getItem("session_id")!,
      });
    }

    ref.current = setInterval(() => {
      if(AuthService.getCurrentUser()) {
        sessionContinue({
          session_id: sessionStorage.getItem("session_id")!,
          is_land: false,
        })
      } else {
        sessionStorage.removeItem(
          "session_id");
        naviagte('/app/auth');
      }
    }, 5000);

    return () => {
      if(ref.current){
        clearInterval(ref.current)
      }
    }
  }, [])
  return (
    <div className="App" style={{ margin: 0 }}>
      <ErrorBoundary
        fallback={
          <ErrorPage />
        }
      >
        <Suspense fallback={<div>Загрузка...</div>}>
          <Routes>
            <Route path="/app" element={
              <RequireAuth>
                <MainPage />
              </RequireAuth>
            }/>
            <Route
              path={MainRoutes.IMAGE_PAGE}
              element={
                <RequireAuth>
                  <ImagePage />
                </RequireAuth>
              }/>
            <Route
              path={MainRoutes.DRESSING_ROOM}
              element={
                <RequireAuth>
                  <DressingPage />
                </RequireAuth>
              }/>
            <Route path="/app/auth" element={<AuthPage />} />
            <Route path="/app/error" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
