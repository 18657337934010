import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import {Provider} from "react-redux";
import AuthService from "./hooks/useLoginStatus";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let land_session_id = crypto.randomUUID();
if(!AuthService.getCurrentSessionId()) {
  document.cookie = `session_id=${land_session_id}; max-age=1800`;
  sessionStorage.setItem("session_id", land_session_id);
} else {
  sessionStorage.setItem("session_id", AuthService.getCurrentSessionId() as string);
  document.cookie = `session_id=${AuthService.getCurrentSessionId() as string}; max-age=1800`;
}


root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

