export enum MainRoutes {
  HOME = "/app",
  IMAGE_PAGE = '/app/image-choosing',
  DRESSING_ROOM = "/app/dressing-room"
}

export enum ErrorsRoutes {
  UNKNOWN = "/app/error",
  ERROR403 = "/app/403",
  ERROR404 = "/app/404",
  ERROR500 = "/app/500",
}