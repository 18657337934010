import {FC, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthService from "../../hooks/useLoginStatus";
import {useUserCredentialsMutation} from "../../store/services/apis";
import {useLazyGetUsersQuery} from "../../store/services/yandex";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const naviagte = useNavigate();
  const [userCredentials] = useUserCredentialsMutation();
  const [trigger, {
    data,
    isLoading,
    error,
    isSuccess
  }] = useLazyGetUsersQuery();

  useEffect(() => {
    if(data) {
      userCredentials({
        email: JSON.parse(data),
        session_id: sessionStorage.getItem("session_id")!,
      }).then((response) => {
        // @ts-ignore
        if (response && response.data && response.data.msg === "ok") {
          // @ts-ignore
          document.cookie = `user=${sessionStorage.getItem("session_id")!}; max-age=1800`;
        } else {
          window.location.href = "https://yookassa.ru/my/i/ZoF2C73E750C/l";
          return null;
        }
      });
      setLoading(false);
    }
  }, [data]);

  const login = useCallback((email: string) => {
    trigger({});
  }, [data, trigger]);

  useEffect(() => {
    if(window.location && window.location.hash && window.location.hash.split('=')[1].split('&')[0]) {
      document.cookie = `auth=${window.location.hash.split('=')[1].split('&')[0]}; max-age=140`;
      login(window.location.hash.split('=')[1].split('&')[0]);
    } else {
      if (!AuthService.getCurrentUser()) {
        naviagte("/app/auth");
      } else {
        setLoading(false);
      }
    }
  }, []);

  return (
    <div>
      {loading && <div>Загрузка...</div>}
      {!loading && children}
    </div>
  );
};

export default RequireAuth;