import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from "../../../hooks/useLoginStatus";

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://login.yandex.ru',
    prepareHeaders: (headers, query) => {
        const token = AuthService.getCurrentAuth();
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        return headers
    },
})

export const yandexApi = createApi({
    reducerPath: "customersApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => ({
                url: `/info?&format=json`,
                method: "get",
                responseHandler: "text"
            }),
        }),
    })
});
export const {
    useLazyGetUsersQuery
} = yandexApi;
