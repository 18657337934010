import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ButtonClickProps,
  SessionContinueProps,
  OpenAppProps, SendPhotoProps, UserCredentialsProps, CategoryPickProps, TryOnClothProps, BuyProps,
} from "./types";
import { createBaseQuery } from "../../config/createBaseQuery";

const baseQuery = createBaseQuery(process.env.REACT_APP_API_URL ?? '');

export const tryThisApi = createApi({
  reducerPath: "app",
  tagTypes: ["app"],
  baseQuery,
  endpoints: (builder) => ({
    openApp: builder.mutation<string, OpenAppProps>({
      query: (arg) => ({
        url: `/open_app`,
        method: "post",
        body: arg,
      }),
      invalidatesTags: ["app"],
    }),
    sessionContinue: builder.mutation<string, SessionContinueProps>({
      query: (arg) => ({
        url: `/session_continue`,
        method: "post",
        body: arg,
      }),
      extraOptions: { maxRetries: 0 }
    }),
    buttonClick: builder.mutation<string, ButtonClickProps>({
      query: (data) => ({
        url: `/button_click`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["app"],
    }),
    sendPhoto: builder.mutation<string, SendPhotoProps>({
      query: (data) => ({
        url: `/send_photo`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["app"],
    }),
    userCredentials: builder.mutation<string, UserCredentialsProps>({
      query: (data) => ({
        url: `/user_credentials`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["app"],
    }),
    categoryPick: builder.mutation<string, CategoryPickProps>({
      query: (data) => ({
        url: `/category_pick`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["app"],
    }),
    buyCloth: builder.mutation<string, BuyProps>({
      query: (data) => ({
        url: `/buy`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["app"],
    }),
    tryOnCloth: builder.mutation<string, TryOnClothProps>({
      query: (data) => ({
        url: `/try_on_cloth`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["app"],
    }),
  }),
});
export const {
  useButtonClickMutation,
  useOpenAppMutation,
  useSessionContinueMutation,
  useBuyClothMutation,
  useCategoryPickMutation,
  useSendPhotoMutation,
  useUserCredentialsMutation,
  useTryOnClothMutation
} = tryThisApi;
